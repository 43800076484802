const _HostCommon = {
  iframeOptions: { scrolling: 'no' },

  identifier (dataset) {
    return [ dataset.partnerKey, dataset.itemId ].filter(Boolean).join('/')
  },

  height (dataset) {
    return dataset.height && dataset.height !== 'auto' ?
      dataset.height : '204'
  },

  autoHeight (dataset) {
    return !dataset.height || dataset.height === 'auto'
  },

  isValid (dataset) { return dataset.partnerKey },
}

export default _HostCommon
