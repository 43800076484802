import _HostCommon from './_HostCommon'
import searchToDonate from '../searchToDonate'

const Search = {
  ..._HostCommon,
  className: 'plg-search',
  path: '/widgets/search',
  params: [
    'organizationId',
    'organizationIds',
    'multipleOrganizations',
    'maxOrganizations',
    'beneficiaryId',
    'beneficiaryUuid',
    'beneficiaryType',
    'ein',
    'goal',
    'eventType',
    'featuredId',
    'featuredUuid',
    'featuredCopy',
    'featuredPaid',
    'template',
    'matching',
  ],

  init: searchToDonate,
}

export default Search
