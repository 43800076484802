import _HostCommon from './_HostCommon'

const Crowd = {
  ..._HostCommon,
  className: 'plg-crowd',
  path: '/widgets/crowd',
  params: [ 'goal', 'purpose', 'template', 'eventType' ],
}

export default Crowd
