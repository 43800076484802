const createMessageHandler = (origin, callback) => {
  const origins = new Set([ origin ])

  origins.add(origin.replace(/pledgeling\.test$/, 'pledge.test'))
  origins.add(origin.replace(/pledgeling\.com$/, 'pledge.to'))
  origins.add(origin.replace(
    /^https:\/\/hello(-([^\.]+))?\.pledge(ling)?\.(com|to|test)/,
    (m, prefSub, sub, ling, tld) =>
      `https://${sub || 'www'}.pledge.${tld == 'com' ? 'to' : tld}`)
  )

  const handleMessage = e => {
    if (origins.has(e.origin))
      callback(e)
  }

  return handleMessage
}

export default createMessageHandler
