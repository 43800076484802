const Impact = {
  className: 'plg-impact',
  path: '/widgets/impact',
  params: [ 'responsive' ],

  iframeOptions: { scrolling: 'no' },

  identifier (dataset) { return dataset.widgetId },

  height (dataset, element) {
    const width = dataset.width || '100%'

    const calcWidth = /%$/.test(width) ?
      element.offsetWidth * (parseFloat(width) / 100) :
      parseInt(width)

    return calcWidth < 400 ?
      calcWidth * 191 / 250 :
      calcWidth * 73 / 400
  },

  autoHeight () { return true },

  isValid (dataset) { return dataset.widgetId },
}

export default Impact
