import 'intersection-observer'
import createMessageHandler from 'src/utils/createMessageHandler'
import safeJSON from 'src/utils/safeJSON'

const iframes = []

const notifyIframe = (iframe, isIntersecting) => {
  iframe.contentWindow?.postMessage(JSON.stringify({
    message: 'isIntersecting',
    data: isIntersecting,
    frameToken: iframe._frameToken,
  }), '*')
}
const handleIntersection = entries =>
  entries.forEach(entry => {
    if (entry.target._frameToken)
      notifyIframe(entry.target, entry.isIntersecting)
    else
      entry.target._isIntersecting = entry.isIntersecting
  })

const intersectionLoaded = (source, frameToken) => {
  const iframe = iframes.find(iframe => iframe.contentWindow == source)
  if (!iframe) return

  iframe._frameToken = frameToken
  notifyIframe(iframe, iframe._isIntersecting)
  delete iframe._isIntersecting
}

const handleMessage = e => {
  const data = safeJSON(e.data)

  switch (data.message) {
    case 'intersectionLoaded':
      return intersectionLoaded(e.source, data.frameToken)
  }
}

const Intersection = {
  add (iframe) {
    iframes.push(iframe)
    this.observer.observe(iframe)
  },

  init (origin) {
    this.observer = new IntersectionObserver(handleIntersection)
    addEventListener('message', createMessageHandler(origin, handleMessage))
  }
}

export default Intersection
