import _HostCommon from './_HostCommon'
import Search from './Search'

const Host = {
  ..._HostCommon,
  className: 'plg-host',
  path: '/widgets/host',
  params: Search.params.concat('purpose'),
}

export default Host
