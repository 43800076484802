import createMessageHandler from 'src/utils/createMessageHandler'
import safeJSON from 'src/utils/safeJSON'

const iframes = []

const resetTransitionProps = e => {
  e.target.style.transition = ''
  e.target.removeEventListener('transitionend', resetTransitionProps)
}

const resizeWidget = (source, height, smooth) => {
  const iframe = iframes.find(iframe => iframe.contentWindow == source)

  if (iframe && iframe.style.height != height) {
    if (smooth) {
      iframe.style.transition = 'height 0.3s'
      iframe.addEventListener('transitionend', resetTransitionProps)
    }
    iframe.style.height = height
  }
}

const handleMessage = e => {
  const data = safeJSON(e.data)
  let smooth = false

  switch (data.message) {
    case 'setWidgetHeightSmooth':
      smooth = true
    case 'setWidgetHeight':
      return resizeWidget(e.source, `${data.data}px`, smooth)
  }
}

const AutoHeight = {
  add (iframe) {
    iframes.push(iframe)
  },

  init (origin) {
    addEventListener('message', createMessageHandler(origin, handleMessage))
  }
}

export default AutoHeight
