const Goal = {
  className: 'plg-goal',
  path: '/widgets/goal',
  params: [
    'showActiveTotals',
    'showChangeAction',
    'showDonateAction',
  ],

  iframeOptions: { scrolling: 'no' },
  iframeStyle: { transition: 'height 0.3s' },

  identifier (dataset) {
    return [ dataset.partnerKey, dataset.itemId ].filter(Boolean).join('/')
  },

  height () { return '150' },

  autoHeight () { return true },

  isValid (dataset) { return dataset.partnerKey && dataset.itemId },
}

export default Goal
