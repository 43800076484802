import _HostCommon from './_HostCommon'
import searchToDonate from '../searchToDonate'

const Search = {
  ..._HostCommon,
  className: 'plg-search-to-donate',
  path: '/widgets/search-to-donate',
  params: [ 'ein', 'template' ],

  init: searchToDonate,
}

export default Search
