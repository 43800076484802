import createMessageHandler from 'src/utils/createMessageHandler'
import safeJSON from 'src/utils/safeJSON'

const initHearts = (origin, data) => {
  const src = `${origin}/assets/hearts-rain.js`
  const dispatchHeartsEvent = () =>
    dispatchEvent(new CustomEvent('RainDownTheHearts', { detail: data }))

  if (document.querySelector(`[src="${src}"]`)) {
    dispatchHeartsEvent()
  } else {
    const script = document.createElement('script')
    script.onload = dispatchHeartsEvent
    script.src = src
    document.body.appendChild(script)
  }
}

const handleMessage = e => {
  const data = safeJSON(e.data)

  switch (data.message) {
    case 'RainDownTheHearts':
      return initHearts(e.origin, data.data)
  }
}

const rainHearts = origin =>
  addEventListener('message', createMessageHandler(origin, handleMessage))

export default rainHearts
