import MutationSummary from 'mutation-summary'

import Config       from './config/Config'
import AutoHeight   from './AutoHeight'
import Intersection from './Intersection'
import rainHearts   from './rainHearts'
import createWidget from './createWidget'

const script = document.currentScript
const origin = new URL(script.src, location).origin

const initWidget = element => {
  if (element.getAttribute(Config.LOAD_ATTR)) return
  element.setAttribute(Config.LOAD_ATTR, '1')

  const widgetTypes = Object.keys(Config.Widgets)
  .filter(className => element.className.split(' ').indexOf(className) > -1)

  if (widgetTypes.length > 1)
    return console.error('Widget can only be of one type', widgetTypes)

  const widgetType = Config.Widgets[widgetTypes[0]]

  const { iframe, autoHeight, intersection } =
  createWidget(element, widgetType, origin)

  if (autoHeight)
    AutoHeight.add(iframe)

  if (intersection)
    Intersection.add(iframe)
}

const initWidgets = () => {
  const selector = '.' + Object.keys(Config.Widgets).join(',.')
  document.querySelectorAll(selector).forEach(initWidget)

  new MutationSummary({
    callback (summaries) {
      summaries[0].added.forEach(initWidget)
    },
    queries: [{ element: selector }]
  })
}

export const init = () => {
  const dataset = script.dataset

  if (dataset.exposeAs)
    window[dataset.exposeAs] = { init: initWidgets }

  AutoHeight.init(origin)
  Intersection.init(origin)
  setTimeout(() => rainHearts(origin), 1)

  // Spin it up on load with async powers  =]
  if (document.readyState === 'loading')
    document.addEventListener('DOMContentLoaded', initWidgets)
  else
    setTimeout(initWidgets, 1)
}
